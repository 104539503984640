.entrada{
    margin-top: 2rem;
    width: 50%;
    padding: 10px 20px;    
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    font-size: 25px;
    cursor: pointer;
    text-align: center;
    
  }

  .resultado {    
    font-size: 50px;
    font-weight: bold;
  }

  .contenedor{
    display: grid;
    justify-items: center;
    align-items: center;
  }
.miBoton {  
    margin-top: 5rem;
    margin-bottom: 3rem;
    width: 50%;
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 25px;
  }

.titulo {
    color: "#182983";
    margin-top: 10rem;
    margin-bottom: 5rem;
    text-align: center;
}