.NavMenu {
    background-color: #d0d1d4;
}

.NavPaginas {
    margin-left: 30%;
}

.nav-link {
    color: #182983 !important;
    font-weight: 600;
}

.nav-link.active {
    color: red !important;
}