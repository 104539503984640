#sticky-footer {
    background-color: #d0d1d4;
    color: #182983 !important;
}

.ubicacion {
    font-weight: 600;
}

.emerFrase {
    text-align: right;
    padding-right: 30%;
    padding-top: 1px;
}

.direccion {
    margin-bottom: 20px;
    text-align: left;
    padding-left: 30%;
}

.horario {
    text-align: left;
    padding-left: 30%;
}

.copyright {
    padding-top: 40px;
}

.dondeEstamos {
    font-weight: 600 !important;
    color: #182983 !important;
    text-decoration: none !important;
}

.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}